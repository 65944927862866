.search-table {
	min-height: 50vh;

	.table-category-item {
		margin-bottom: 20px;
	}

	.table-item {
		display: flex;
		align-items: center;
		padding: 4px 0;
		border-bottom: 1px solid rgba(79, 74, 74, 0.1);
	}

	.folder-icon {
		width: 56px;
		height: 56px;
		padding: 10px;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			filter: brightness(0) invert(1);
		}
	}
}
