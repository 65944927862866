.search-table {
	min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		object-fit: cover;
		margin: auto 12px;
		vertical-align: middle;
	}
}

// add overlay with text "Upload Avatar" onhover
.upload-avatar {
	width: 100px;
	height: 100px;
	margin-bottom: 32px;
	position: relative;

	img {
		width: 100%;
	}
	
	&:hover {
		&::before {
			content: 'Upload Avatar';
			position: absolute;
			top: 50%;
			left: 50%;
			background-color: rgba(0, 0, 0, 0.5);
			transform: translate(-50%, -50%);
			color: white;
			font-size: 16px;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;
			text-align: center;
		}
	}
}