html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

#root {
	height: 100%;
}

.app__wrapper {
	display: flex;
	width: 100%;
	height: 77vh;
	border-top: 1px solid rgba(0, 0, 0, 0.1);

	@media only screen and (max-width: 930px) {
		.sidebar {
			width: 100%;
			position: absolute;
			left: -100%;
		}
	}

	@media only screen and (max-width: 768px) {
		.str-chat__thread,
		.pinned-messages__container {
			position: fixed;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			z-index: 1000;
			width: 100vw;
			max-width: 100%;
			background: white;
		}
	}
}

.sidebar {
	width: 312px;
}

.channel__container {
	height: 100%;
	width: 100%;
	min-width: 0;
	flex: 1;
}

.str-chat__thread,
.pinned-messages__container {
	width: 30%;
	min-width: 300px;
}

.str-chat__date-separator {
	padding: 8px 8px 0 8px;
}

.manage-members-modal {
	.members-list {
		overflow: auto;
		max-height: 70vh;
		padding-right: 16px;
	}

	.member-item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 12px 0;

		.member-info {
			display: flex;
			align-items: center;

			.str-chat__avatar {
				margin-right: 10px;

				> img {
					border-radius: 100%;
				}
			}
		}

		.member-actions {
			button {
				font: var(--header-subtitle-text);
				color: var(--text-low-emphasis-color);
				border: none;
				background: none;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;
				cursor: pointer;
			}

			button:hover {
				color: var(--text-color);
			}
		}
	}
}
