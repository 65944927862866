.search-table {
	min-height: 50vh;

	.table-item {
		display: flex;
		align-items: center;
		padding: 4px 0;
		border-bottom: 1px solid rgba(79, 74, 74, 0.1);
	}

	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		margin-right: 8px;
	}
}
