.typing-indicator__input {
  color: rgba(0, 0, 0, 0.9);
  opacity: 0.5;
}

.typing-indicator__list {
  color: rgba(255, 255, 255, 0.75);
  opacity: 0.5;
}

.dot {
  border-radius: 50%;
  animation: wave2 1.1s linear infinite;
}

.typing-indicator__input__text {
  font-size: 11px;
  line-height: 13px;
  color: rgba(0, 0, 0, 0.9);
}

.typing-indicator__list .dots .dot {
  background: var(--static-background-color);
}

.typing-indicator__input .dots .dot {
  background: #000;
}

.typing-indicator__list .dots .dot:nth-child(2),
.typing-indicator__input .dots .dot:nth-child(2) {
  animation-delay: -0.9s;
  opacity: 0.5;
}

.typing-indicator__list .dots .dot:nth-child(3),
.typing-indicator__input .dots .dot:nth-child(3) {
  animation-delay: -0.8s;
  opacity: 0.2;
}

@keyframes wave2 {
  0%,
  60%,
  100% {
    opacity: 1;
  }
  30% {
    opacity: 0.5;
  }
}