.companies {
	margin: 28px 0;

	.company {
		margin-bottom: 20px;

		.company-header {
			display: flex;
			align-items: center;
		}
	}

	.contacts {
		margin: 20px 0;

		.contacts-header {
			margin-bottom: 12px;
		}

		.contact-item {
			margin-bottom: 12px;
		}
	}
}
