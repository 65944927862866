.tableActionsWrapper {
	display: flex;
	gap: 8px;
}

.editSwitchSection {
	display: flex;
	gap: 8px;
	margin: 8px 0;
}
