.search-table {
	min-height: 50vh;

	.table-category-item {
		margin-bottom: 20px;
	}

	.table-item {
		display: flex;
		align-items: center;
		padding: 4px 0;
		border-bottom: 1px solid rgba(79, 74, 74, 0.1);
	}

	.avatar {
		width: 40px;
		height: 40px;
		border-radius: 100%;
		margin-right: 8px;
	}

	.add-all-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		.title {
			color: black;
			margin: 8px 0;
		}
	}
}
